<!-- 个人中心 -->
<template>
  <div id="searchPayDetail">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="textForm.projectName"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <div v-show="diyFormList.length>0" class="boxstyle">
          <van-form :model="diyTemp" @submit="onSubmit">
            <div class="paymonry">
              <img src="@/assets/new_icon/item.svg" class="icon">
              {{ $t('commonPay.customerForm') }}
            </div>
            <van-row v-for="item in diyFormList" :key="item.key" style="border-bottom: 0.02667rem solid #ebedf0;width:100%;">
              <van-col>
                <van-field v-if="item.type==='input'" v-model="diyTemp[item.key]" :placeholder="item.placeholder" :readonly="item.readonly" :name="item.comName" :label="item.label" :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: '必填项不能为空' }]" />
                <van-field v-if="item.type==='switch'" :name="item.comName" :label="item.label" :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: '必填项不能为空' }]">
                  <template #input>
                    <van-radio-group v-if="item.type==='switch'" v-model="diyTemp[item.key]" direction="horizontal">
                      <van-radio v-for="o in statusOptions" :key="o.key" :name="o.key">{{ o.value }}</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <van-field
                  v-else-if="item.type==='select'"
                  readonly
                  clickable
                  :label="item.label"
                  :value="diyTemp[item.key]"
                  :placeholder="item.placeholder"
                  :rules=" item.pros== null? [{ required: false }] :[{ required: true, message: '必填项不能为空' }]"
                  @click="pickerClick(item)"
                />
                <van-popup v-model="item.showPicker" round position="bottom">
                  <van-picker
                    show-toolbar
                    :columns="item.optionsDate"
                    @cancel="item.showPicker = false"
                    @confirm="onConfirm"
                  />
                </van-popup>
              </van-col>
            </van-row>
            <div class="bottom">
              <van-button type="info" size="large" class="bottombutton" native-type="submit">{{ $t('main.next') }}</van-button>
            </div>
          </van-form>
        </div>
        <div class="boxstyle">
          <div class="boxstylemoney">
            <div class="paymonry">
              <img src="@/assets/new_icon/pay-message.svg" class="icon">
              {{ $t('commonPay.title1') }}
            </div>
            <van-cell :title="$t('orderDetail.num')" :value="this.$route.query.batchno" />
            <van-cell :title="$t('searchDetail.billAmount')" :value="textForm.payamtStr" class="red" />
            <van-cell :title="$t('searchDetail.paid')" :value="textForm.paidAmtStr" class="red" />
            <van-cell :title="$t('searchDetail.deduction')" :value="textForm.rebateAmountStr" class="red" />
            <van-cell :title="$t('pay.unpaid-amount')" :value="textForm.unPaidAmtStr" class="red" />
            <van-cell :title="$t('pay.full-payment')">
              <van-switch
                v-model="fullPay"
                :disabled="btnflag"
                size="24px"
                @change="switchChange"
              />
            </van-cell>
            <van-cell
              :title="$t('searchDetail.this')"
              :value="fenToYuan(textForm.actualPayAmountStr * 100)"
              class="red color"
              @click="btnChange"
            >
              <template #right-icon>
                <img src="@/assets/icon/write.jpg">
              </template>
            </van-cell>
          </div>
        </div>

      </div>
      <van-dialog v-model="dialog" :title="$t('searchPay.fix')" show-cancel-button :before-close="chargeBtn">
        <div class="dialog-title">（最底缴费金额：￥{{ fenToYuan(temp.payLimit) }}）</div>
        <!-- 允许输入正整数，调起纯数字键盘 -->
        <van-field ref="getFocus" v-model="money" style="width: 90%;margin: 20px;border: 1px solid #eee;" type="number" />
      </van-dialog>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { queryDetail, createQueryPaymentOrder } from '@/api/queryPayment/queryPayment'
// import { queryDetail } from '@/api/queryPayment/queryPayment'
import { fenToYuan } from '@/utils/common'
import store from '@/store'
import { Toast } from 'vant'
export default {
  name: 'SearchPayDetail',
  components: {
    [Toast.name]: Toast
  },
  data() {
    return {
      urlMoney: '',
      btnflag: true,
      money: '',
      fullPay: true,
      dialog: false,
      pickerList: {},
      diyFormList: [],
      diyTemp: {
      },
      statusOptions: [
        { key: 'YES', value: '是' },
        { key: 'NO', value: '否' }
      ],
      diyRules: {},
      textForm: {
        projectName: '',
        payamtStr: 0,
        paidAmtStr: 0,
        rebateAmountStr: 0,
        unPaidAmtStr: 0,
        actualPayAmountStr: 0,
        nameListId: '',
        projectId: ''
      },
      temp: {
        projectName: '',
        engName: '',
        amtFlag: 'YES', // 固定金额是否
        authentication: 'YES',
        description: '',
        payamt: '',
        payAmount: 0,
        proModelUrl: '',
        rebateAmount: 0,
        billAmountStr: '',
        proTypeInfoId: null,
        imgFile: null,
        count: 1,
        sumAmount: 0,
        maxCount: 1,
        imgUrl: '',
        allowRebate: '',
        formAssemblyVoList: [],
        payUserAssemblyDataVO: [],
        payLimit: '',
        payLimitStr: '',
        actualPayAmount: 0,
        schoolCode: window.localStorage.getItem('schoolcode')
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.init()
    this.urlMoney = Number(this.$route.query.actualPayAmountStr)
  },
  methods: {
    btnChange() {
      this.dialog = true
    },
    // 部分缴费弹窗
    chargeBtn(action, done) {
      if (action === 'confirm') {
        this.money = Number(this.money)
        // 判断大于应缴金额
        if (this.money > this.urlMoney) {
          Toast.fail('不能大于应缴金额')
          done(false)
          return false
        }
        // 判断小于最底限额
        if (this.money * 100 < this.temp.payLimit) {
          Toast.fail('不能小于最小限额')
          done(false)
          return false
        }
        // 判断.
        if (isNaN(this.money)) {
          Toast.fail('请输入正确金额')
          this.money = ''
          done(false)
          return false
        }
        this.textForm.actualPayAmountStr = this.money
        this.money = ''
        if (this.textForm.actualPayAmountStr === this.urlMoney) {
          this.fullPay = true
        } else {
          this.fullPay = false
        }
        done()
      } else {
        this.money = ''
        if (Number(this.textForm.actualPayAmountStr) === this.urlMoney) {
          this.fullPay = true
        } else {
          this.fullPay = false
        }
        done()
      }
    },
    // 全额缴费开关
    switchChange() {
      if (this.fullPay === false) {
        this.dialog = true
      } else {
        this.textForm.actualPayAmountStr = this.urlMoney
      }
    },
    toPay() {
      // console.log('pay')
    },
    onConfirm(value) {
      // console.log(value)
      this.diyTemp[this.pickerList.key] = value
      this.pickerList.showPicker = false
    },
    // 带参数跳转页面
    onSubmit(item) {
      // console.log(this.textForm)
      var createOrderVO = {}
      createOrderVO.payProjectVO = {}
      createOrderVO.payProjectVO.nameListId = this.textForm.nameListId
      createOrderVO.payProjectVO.actualPayAmountStr = this.textForm.actualPayAmountStr
      var payUserAssemblyDataVO = {}
      var otherDataArray = []
      this.diyFormList.forEach(item => {
        if (item.key === 'NAME') {
          payUserAssemblyDataVO.name = item.value
        } else if (item.key === 'SEX') {
          payUserAssemblyDataVO.sex = item.value
        } else if (item.key === 'IDSERIAL') {
          payUserAssemblyDataVO.idserial = item.value
        } else if (item.key === 'IDNUMBER') {
          payUserAssemblyDataVO.idNumber = item.value
        } else if (item.key === 'PHONE') {
          payUserAssemblyDataVO.phone = item.value
        } else if (item.key === 'EMAIL') {
          payUserAssemblyDataVO.email = item.value
        } else {
          var obj = {}
          obj.key = item.key
          obj.value = this.diyTemp[item.pros]
          otherDataArray.push(obj)
        }
        payUserAssemblyDataVO.otherData = JSON.stringify(otherDataArray)
      })
      createOrderVO.payUserAssemblyDataVO = payUserAssemblyDataVO
      createQueryPaymentOrder(createOrderVO).then((response) => {
        // console.log(response)
        var amount = fenToYuan(response.data.amount)
        this.$router.push({ path: '/person', query: { amount: amount, projectId: this.$route.query.projectId, orderId: response.data.id }})
      })
    },
    pickerClick(item) {
      this.pickerList = {}
      // console.log(item)
      this.pickerList = item
      item.showPicker = true
    },
    init() {
      this.textForm.actualPayAmountStr = this.$route.query.actualPayAmountStr
      const id = this.$route.query.id
      queryDetail(id).then(response => {
        // 数据反显
        this.textForm.nameListId = id
        this.textForm.projectName = response.data.projectName
        this.textForm.payamtStr = response.data.payamtStr
        this.textForm.paidAmtStr = response.data.paidAmtStr
        this.textForm.rebateAmountStr = response.data.rebateAmountStr
        this.textForm.unPaidAmtStr = response.data.unPaidAmtStr
        this.temp = response.data
        // 遍历 自定义表单内容
        this.temp.formAssemblyVoList.forEach(item => {
          var obj = {}
          obj.id = item.id
          obj.type = item.assemblyType
          obj.placeholder = item.tipsInfo
          obj.pros = item.mustStatus
          obj.label = item.showName
          obj.key = item.assemblyKey
          if (obj.type === 'input') {
            obj.comName = 'input'
            this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'blur' }])
          } else if (obj.type === 'select') {
            obj.showPicker = false
            obj.options = []
            this.$set(this.diyRules, obj.key, [{ required: true, message: '必填项不能为空', trigger: 'change' }])
            item.listAssemblyDetail.forEach(op => {
              var optionObj = {}
              optionObj.id = op.id
              optionObj.key = op.detailValue
              optionObj.label = op.showDetail
              optionObj.value = op.detailValue
              obj.options.push(optionObj)
            })
            obj.optionsDate = []
            obj.optionsDate = obj.options.map(v => v.label)
          // obj.options.map(v => {
          //   obj.optionsDate.push({
          //     defaultIndex: v.key,
          //     values: v.label
          //   })
          // })
          } else if (obj.type === 'switch') {
            obj.comName = 'radio'
            this.$set(this.diyRules, obj.key, [{ required: true, message: '请选择', trigger: 'blur' }])
          }
          this.diyFormList.push(obj)
          console.log(this.diyFormList)
        })
        if (this.temp.payLimit > 0) {
          this.btnflag = false
        }
      })
    },
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/searchPay' })
    },
    // 带参数跳转页面
    dealwith(item) {
      this.$router.push({
        path: '/login'
      })
      store.dispatch('user/logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>
<style scoped lang="scss">
.dialog-title{
  font-size: 10px;
  color: #F96662;
  width: 100%;
  text-align: center;
}
::v-deep .van-field__control{
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.van-radio-group--horizontal{
  .van-radio--horizontal{
    margin-right: 0;
    margin-left: 10px;
  }
}
.van-col{
  width: 100%;
}
.color{
  display: flex;
  align-items: center;
  .van-cell__value{
    span{
      color: #F96662;
    }
  }
  img{
    width: 13px;
    height: 14px;
    margin-left: 5px;
  }
}
.van-cell__value{
  line-height: 43px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#searchPayDetail {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 100px;
    width: 100%;
    background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 12px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -35px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  // margin: 10px 15px 15px;
  background-color: #ffffff;
  border: 1px white solid;
  // border-radius: 10px;
  // box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.paymonry{
  color:#2E2E2E;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell__title {
    span {
      color:#747474;
      height: 44px;
      line-height: 44px;
    }
}
.van-cell__value {
    span {
      color: #2e2e2e;
    }
}
.boxstyle .van-cell {
    padding: 10px 0;
}
.boxstylemoney {
  .van-cell {
      padding: 0;
  }
  .van-cell:not(:last-child)::after{
    left: 0;
  }
}
.icon{
  width: 14px;
  margin-right: 5px;
  filter: drop-shadow(#2F9FF2 100px 0);
  transform: translateX(-100px);
}
</style>
<style lang="scss">
#searchPayDetail{
    .van-field__control--right{
        color:red
    }
}
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
#searchPayDetail .van-field__label {
    color:#747474;
    line-height: 24px !important;
    height: 24px !important;
}
#searchPayDetail .van-field__value {
    line-height: 24px !important;
    height: 24px !important;
}
</style>

