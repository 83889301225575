import request from '@/utils/request'
// 获取缴费列表
export function getList(data) {
  return request({
    url: 'pay/web/queryPayment/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
// 缴费详情
export function queryDetail(data) {
  return request({
    url: `pay/web/queryPayment/queryDetail/${data}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      return data
    }]
  })
}
export function createQueryPaymentOrder(data) {
  return request({
    url: 'pay/web/queryPayment/createOrder/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
